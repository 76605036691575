<template>
  <div v-loading="loading">
    <el-row style="margin-bottom:15px">
      <el-select v-model="TTSText" filterable style="125px" size="small ">
        <el-option label="TTS测试1" value="TTS测试1" ></el-option>
        <el-option label="TTS测试2" value="TTS测试2"></el-option>
      </el-select>&nbsp;&nbsp;
      <el-button type="primary" @click="playVoice" size="small ">播放语音</el-button>&nbsp;&nbsp;
      <el-popover
          placement="top"
          width="250"
          v-model="popoverShow">
        <p>确定要删除该音乐主机吗?删除后将无法恢复。</p>
        <div style="text-align: right; margin: 0">
          <el-button size="mini" type="text" @click="popoverShow = false">取消</el-button>
          <el-button type="primary" size="mini" @click="deleteMusicHost">确定</el-button>
        </div>
        <el-button type="primary" slot="reference" size="small ">删除设备</el-button>
      </el-popover>&nbsp;&nbsp;
      <el-button type="primary" @click="playSpecified" size="small ">播放测试音乐</el-button>
      <el-button type="primary" @click="musicPlaybackClick" size="small ">指定音乐播放</el-button>
      <el-button type="primary" icon="el-icon-refresh" circle @click="refash" style="float: right" size="small "></el-button>
      <el-dialog
        width="45%"
        title="指定音乐播放"
        :visible.sync="isMusicPlayback"
        append-to-body>
        <el-row style="margin:10px 0px">音乐地址: <el-input placeholder="" size="mini" style="width:250px" v-model="musicInput.musicUrl"></el-input></el-row>
        <el-row style="margin:10px 0px">封面图片: <el-input placeholder="" size="mini" style="width:250px" v-model="musicInput.musicImg"></el-input></el-row>
        <el-row style="margin:10px 0px">音乐名称: <el-input placeholder="" size="mini" style="width:250px" v-model="musicInput.musicName"></el-input></el-row>
        <el-row style="margin:10px 0px">歌手名称: <el-input placeholder="" size="mini" style="width:250px" v-model="musicInput.singerName"></el-input></el-row>
        <el-row style="margin:10px 0px">播放时长: <el-input placeholder="" size="mini" style="width:250px" v-model="musicInput.musicTime"></el-input></el-row>
        <el-row>
          <el-col align="right"><el-button type="primary" @click="musicPlaybackPush">推送</el-button></el-col>
        </el-row>
    </el-dialog>
    </el-row>
    <el-row>
      <div class="control">
        <span class="musicName">{{title}}</span>
        <span class="iconfont icon-kuaitui icon" @click="prevOrNext(1)"></span><!--上一首-->
        <!-- <i class="el-icon-caret-left icon" @click="prevOrNext(1)"></i> -->
        <span class="iconfont icon-bofang icon" @click="playOrPause(2)" v-if="playOrPauseIsShow == 1"></span><!--播放-->
        <span class="iconfont icon-zanting icon" @click="playOrPause(1)" v-if="playOrPauseIsShow == 2"></span><!--暂停-->
        <span class="iconfont icon-kuaijin icon" @click="prevOrNext(2)"></span><!--下一首-->
        <!-- <i class="el-icon-caret-right icon" @click="prevOrNext(2)"></i> -->
        <span class="iconfont icon-zhongzhi icon" @click="musicStop"></span><!--重置-->
        <el-popover
            width="250"
            trigger="hover"
            placement="bottom">
            <el-slider v-model="musicVolume" @change="volumeOrMute(musicVolume)" :marks="{25: '25', 50: '50', 75: '75'}"></el-slider>
          <span class="iconfont icon-shengyin icon" slot="reference" v-if="musicVolume != 0"></span><!--音量-->
          <span class="iconfont icon-jingyin icon" slot="reference" v-if="musicVolume == 0" style="font-size: 29px !important"></span><!--静音-->
        </el-popover><!--音量-->
        <span class="iconfont icon-liebiaoxunhuan icon" @click="playOrOrder(1)" v-if="playoOrderIsShow == 0"></span><!--循环播放-->
        <span class="iconfont icon-suiji icon" @click="playOrOrder(2)" v-if="playoOrderIsShow == 1" style="font-size: 29px !important"></span><!--随机播放-->
        <span class="iconfont icon-danquxunhuan icon" @click="playOrOrder(0)" v-if="playoOrderIsShow == 2"></span><!--单曲循环-->
        <span class="iconfont icon-shuaxin icon" @click="playOrOrder(0)" v-if="playoOrderIsShow == 3"></span><!--顺序播放-->
        <div v-if="false"><!--因接口无顺序播放，故调整点击时下一播放方式-->
          <span class="iconfont icon-liebiaoxunhuan icon" @click="playOrOrder(1)" v-if="playoOrderIsShow == 0"></span><!--循环播放-->
          <span class="iconfont icon-suiji icon" @click="playOrOrder(2)" v-if="playoOrderIsShow == 1" style="font-size: 29px !important"></span><!--随机播放-->
          <span class="iconfont icon-danquxunhuan icon" @click="playOrOrder(3)" v-if="playoOrderIsShow == 2"></span><!--单曲循环-->
          <span class="iconfont icon-shuaxin icon" @click="playOrOrder(0)" v-if="playoOrderIsShow == 3"></span><!--顺序播放-->
        </div>
        <span class="iconfont icon-liebiao icon" @click="musicListIsShow = !musicListIsShow;"></span><!--列表-->
      </div>
    </el-row>
    <el-table
    :data="musicList"
    highlight-current-row
    @row-dblclick="musicListDblClick"
    height="216px"
    size="mini"
    v-if="musicListIsShow">
      <el-table-column prop="title" label="歌曲" min-width="25px"></el-table-column>
      <el-table-column prop="artist" label="歌手" min-width="25px"></el-table-column>
      <el-table-column prop="album" label="专辑" min-width="25px"></el-table-column>
    </el-table>
  </div>
</template>
<script>
  export default {
    props: {
      isShow: {
        type: Boolean,
        default: true,
      },
      isRow: {
        type: Object,
        default: {},
      },
      isState: {
        type: Object,
        default: {},
      },
    },
    data(){
      return {
        musicInput:{
          musicUrl:'',
          musicImg:'',
          musicName:'',
          singerName:'',
          musicTime:'',
        },
        musicPlaybackList:[],//指定音乐播放数据
        isMusicPlayback:false,
        dialogCaoZuoSheBei: this.isShow,
        row: this.isRow,
        state: this.isState,
        popoverShow: false,
        loading: false,
        playOrPauseIsShow: 1,//播放暂停
        playoOrderIsShow: 0,//播放顺序
        title: '当前未播歌曲',//歌曲名
        musicVolume: 0,//音量
        musicList: [],
        musicListIsShow: true,
        TTSText: 'TTS测试1',
      }
    },
    created(){
      console.log(this.row)
      console.log(this.state)
      this.playOrPauseIsShow = this.state.currentSte
      this.musicVolume = this.state.musicVolume
      this.playoOrderIsShow = this.state.playMode
      this.getMusicInfo()
      this.getMusicList()
    },
    watch: {
      dialogCaoZuoSheBei(n, o) {
        this.$emit('close');
      },
    },
    methods: {
      //指定音乐播放
      musicPlaybackClick(){
        this.isMusicPlayback=true
      },
      //指定音乐播放 推送按钮
      musicPlaybackPush(){
        var obj=this.musicInput
        console.log("aaa",this.musicList);
        for(var key in obj){
          if(!obj[key]){
            this.$message.warning("输入框不能为空")
            return;
          }
        }
        this.$axios({
              method:"post",
              url:'/common2/devices/operate',
              data:{
                id:'1',
                fileName:"1.mp3",
                hdImgUrl:"1.jpg",
                artist:this.musicInput.singerName,
                album:"单曲",
                albumId:1,
                isshoucang:0,
                sort:1,
                type:"ONLINE",
                title:this.musicInput.musicName,
                url:this.musicInput.musicUrl,
                imgUrl:this.musicInput.musicImg,
                duration:229,
                fileSize:3586,
                lyric:"暂无资料",
                operationType:"ControlPlayOnlySongs",
                devId:this.row.jourDeviceId
              }
            }).then(res=>{
          this.$message.success("推送成功")
          console.log(res)
          this.isMusicPlayback=false
        }).catch(err=>{
          this.isError(err,this)
        })
      },
      getMusicInfo() {//获取当前歌曲信息
        return this.$axios({
          method: 'post',
          url: '/common2/devices/operate',
          data: {
            devId: this.row.jourDeviceId,
            operationType: 'GetSongInformation',
          }
        }).then(res=>{
          let obj = JSON.parse(res.data.result).body.count
          // this.title = obj.title ? obj.title : '当前未播歌曲'
          obj.title ? this.$set(this , 'title' , obj.title) : '当前未播歌曲'
          console.log(obj)
          this.getOneState()
          return 
        }).catch(err=>{
          this.isError(err,this)
        })
      },
      getOneState(){//获取单条设备状态
        return this.$axios({
          method: 'get',
          // url: `/common2/devices/getBatchState`,
          url: `/common2/devices/getDevicesState`,
          params: {
            ids: this.row.jourDeviceId
          }
        }).then(res=>{
          // console.log(res.data.result[0])
          this.state = res.data.result[0]
          this.$set(this , 'playOrPauseIsShow' , this.state.currentSte)
          this.$set(this , 'musicVolume' , this.state.musicVolume)
          this.$set(this , 'playoOrderIsShow' , this.state.playMode)
          // this.playOrPauseIsShow = this.state.currentSte
          // this.musicVolume = this.state.musicVolume
          // this.playoOrderIsShow = this.state.playMode
          console.log(this.state)
          return 
        }).catch(err=>{
          this.isError(err,this)
        })
      },
      getMusicList() {//当前歌曲列表
        return this.$axios({
          method: 'post',
          url: '/common2/devices/operate',
          data: {
            devId: this.row.jourDeviceId,
            operationType: 'GetPlaySongList',
          }
        }).then(res=>{
          let arr = JSON.parse(res.data.result).body.count
          this.musicList = arr.map((value,index)=>{ return JSON.parse(value)})
          console.log(this.musicList)
          return 
        }).catch(err=>{
          this.isError(err,this)
        })
      },
      playVoice() {//播放TTS
        this.$axios({
          method: 'post',
          url: '/common2/devices/operate',
          data: {
            devId: this.row.jourDeviceId,
            operationType: 'PlayTTS',
            tts: this.TTSText,
          }
        }).then(res=>{
        }).catch(err=>{
          this.isError(err,this)
        })
      },
      playSpecified() {//播放指定音乐
        this.$axios({
          method: 'post',
          url: '/common2/devices/operate',
          data: {
            devId: this.row.jourDeviceId,
            operationType: 'ControlPlayOnlySongs',

            id : 1,
            fileName : '测试音乐KissTheRain',
            hdImgUrl : 'http://qcbol5oi2.bkt.clouddn.com/Music.png',
            artist : '测试音乐KissTheRain',
            album : '测试音乐',
            albumId : 1,
            isshoucang : 1,
            sort : 1,
            type : 'ONLINE',
            title : '测试音乐KissTheRain',
            url : 'http://qcbol5oi2.bkt.clouddn.com/KissTheRain.mp3',
            imgUrl : 'http://qcbol5oi2.bkt.clouddn.com/Music.png',
            duration : 252000,
            fileSize : 995,
            lyric : '',
            
            // // id : 1,
            // // fileName : '1',
            // // hdImgUrl : '1',
            // // artist : '1',
            // // album : '1',
            // albumId : 1,
            // isshoucang : 0,
            // sort : 1,
            // // type : 歌曲类型： 本地-LOCAL TF 卡歌曲-TF U 盘歌曲-UDISK 在线-ONLINE
            // // title : '1',
            // url: "http://172.18.88.161/KissTheRain.mp3",
            // // imgUrl : '1',
            // duration : 1,
            // fileSize : 1,
            // // lyric: '1',
          }
        }).then(res=>{
          this.getMusicInfo()
        }).catch(err=>{
          this.getMusicInfo()
          this.isError(err,this)
        })
      },
      async refash() {//刷新
        try {
          await this.getMusicInfo()
          await this.getMusicList()
        } catch {
          console.log(err)
        }
      },
      playOrPause(value) {//切换播放暂停图标
        // this.playOrPauseIsShow = value
        if (value == 1) {//播放
          this.$axios({
            method: 'post',
            url: '/common2/devices/operate',
            data: {
              devId: this.row.jourDeviceId,
              operationType: 'Play',
            }
          }).then(res=>{
            this.getMusicInfo()
          }).catch(err=>{
            this.getMusicInfo()
            this.isError(err,this)
          })
        } else if (value == 2) {//暂停
          this.$axios({
            method: 'post',
            url: '/common2/devices/operate',
            data: {
              devId: this.row.jourDeviceId,
              operationType: 'Pause',
            }
          }).then(res=>{
            this.getMusicInfo()
          }).catch(err=>{
            this.getMusicInfo()
            this.isError(err,this)
          })
        }
      },
      prevOrNext(value) {//上一首下一首
        if (value == 1) {//上一首
          this.$axios({
            method: 'post',
            url: '/common2/devices/operate',
            data: {
              devId: this.row.jourDeviceId,
              operationType: 'PreviousTrack',
            }
          }).then(res=>{
            this.getMusicInfo()
          }).catch(err=>{
            this.getMusicInfo()
            this.isError(err,this)
          })
        } else if (value == 2) {//下一首
          this.$axios({
            method: 'post',
            url: '/common2/devices/operate',
            data: {
              devId: this.row.jourDeviceId,
              operationType: 'NextTrack',
            }
          }).then(res=>{
            this.getMusicInfo()
          }).catch(err=>{
            this.getMusicInfo()
            this.isError(err,this)
          })
        }
      },
      musicStop() {//停止
        this.$axios({
          method: 'post',
          url: '/common2/devices/operate',
          data: {
            devId: this.row.jourDeviceId,
            operationType: 'Stop',
          }
        }).then(res=>{
          this.getMusicInfo()
        }).catch(err=>{
          this.getMusicInfo()
          this.isError(err,this)
        })
      },
      volumeOrMute(value) {//调节音量
        this.$axios({
          method: 'post',
          url: '/common2/devices/operate',
          data: {
            devId: this.row.jourDeviceId,
            operationType: 'SetVolume',
            volume: value,
          }
        }).then(res=>{
          this.getMusicInfo()
        }).catch(err=>{
          this.getMusicInfo()
          this.isError(err,this)
        })
      },
      playOrOrder(value) {//切换播放顺序图标
        if (value == 0) {//循环播放
          this.$axios({
            method: 'post',
            url: '/common2/devices/operate',
            data: {
              devId: this.row.jourDeviceId,
              operationType: 'LoopPlayback',
            }
          }).then(res=>{
            this.getMusicInfo()
          }).catch(err=>{
            this.getMusicInfo()
            this.isError(err,this)
          })
        } else if (value == 1) {//随机播放
          this.$axios({
            method: 'post',
            url: '/common2/devices/operate',
            data: {
              devId: this.row.jourDeviceId,
              operationType: 'RandomPlay',
            }
          }).then(res=>{
            this.getMusicInfo()
          }).catch(err=>{
            this.getMusicInfo()
            this.isError(err,this)
          })
        } else if (value == 2) {//单曲循环
          this.$axios({
            method: 'post',
            url: '/common2/devices/operate',
            data: {
              devId: this.row.jourDeviceId,
              operationType: 'SingleCycle',
            }
          }).then(res=>{
            this.getMusicInfo()
          }).catch(err=>{
            this.getMusicInfo()
            this.isError(err,this)
          })
        }
        // else if (value == 3) {//顺序播放
          // this.$axios({
          //   method: 'post',
          //   url: '/common2/devices/operate',
          //   data: {
          //     devId: this.row.jourDeviceId,
          //     operationType: 'LoopPlayback',
          //   }
          // }).then(res=>{
          //   this.getMusicInfo()
          // }).catch(err=>{
          //   this.getMusicInfo()
          //   this.isError(err,this)
          // })
        // }
      },
      musicListDblClick(row) {//双击音乐列表播放
        row.devId = this.row.jourDeviceId
        row.operationType = 'ControlPlayOnlySongs'
        this.$axios({
          method: 'post',
          url: '/common2/devices/operate',
          data: row
        }).then(res=>{
          this.getMusicInfo()
        }).catch(err=>{
          this.getMusicInfo()
          this.isError(err,this)
        })
      },
      deleteMusicHost(){//删除设备
        this.loading = true
        this.$axios({
          method: 'delete',
          url: `/common2/devices/${this.row.jourDeviceId}/deleteMusicHost`,
        }).then(res => {
          this.$message.success('删除成功')
          this.loading = false
          this.popoverShow = false
          this.dialogCaoZuoSheBei = false
        }).catch(err => {
          this.loading = false
          this.popoverShow = false
          this.isError(err,this)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .control{
    float: left;
    font-size: 16px;
    margin: 6px 0;
    display:flex;
    .musicName{
      align-self:center;
      margin-right: 10px;
      width: 200px;
    }
    .icon{
      font-size: 30px !important;
      padding-right: 10px;
      cursor: pointer;
    }
  }
</style>
